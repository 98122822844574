import React from "react";
import "./Home.scss";
import vfxDemo from "../../Assets/Videos/vfx-demo.mp4";
import Work from "./Work/Work";
import downarrow from "../../Assets/Videos/downarrow.gif";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="home-page">
        <div>
          <video autoPlay muted loop className="home-video">
            <source src={vfxDemo} type="video/mp4" />
          </video>
          <div className="down-arrow-container">
            <button
              onClick={() => window.location.replace("/#OurWork")}
              className="button-obj"
            >
              <img src={downarrow} className="down-arrow" alt="downarrow" />
            </button>
          </div>
          <Work />
        </div>
      </div>
    );
  }
}
