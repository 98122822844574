import React, { useState } from "react";
import { vfx_videos, twoD_videos } from "../../Videos";
import Modal from "react-bootstrap/Modal"; // Assuming you're using Bootstrap Modal
import "./Work.scss";

const Work = () => {
  const [selectedVideo, setSelectedVideo] = useState(null); // Track selected video
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [isVfx, setIsVfx] = useState(true); // Track if it's VFX or 2D

  // Function to handle when a video is clicked
  const handleVideoClick = (video) => {
    setSelectedVideo(video); // Set the clicked video as the selected one
    setShowModal(true); // Show the modal
  };

  // Function to close the modal
  const handleClose = () => {
    setShowModal(false); // Hide the modal
  };

  const renderVideos = () => {
    const videos = [];
    var videoList = isVfx ? vfx_videos : twoD_videos;

    // Loop through vfx_videos
    videoList.forEach((vfx, index) => {
      // Add VFX video to the list
      videos.push(
        <div
          key={`vfx-${index}`}
          className="video-item col-lg-4 col-sm-6 mb-4"
          onClick={() => handleVideoClick(vfx)} // Open modal on click
        >
          <img src={vfx.src} alt={vfx.alt} className="img-fluid" />
          <div className="play-button-overlay">
            <i className="bi bi-play-fill"></i>
          </div>
          <div className="video-text d-flex p-3">
            <h4>
              {vfx.artistName}
              {vfx.videoTitle && " - "}
              <span className="fw-light mx-1">{vfx.videoTitle}</span>
            </h4>
          </div>
        </div>
      );
    });
    return videos;
  };

  return (
    <div className="work-page" id="OurWork">
      <div className="work-page-content">
        <div className="d-flex flex-column align-items-center">
          <h1 className="text-white pt-4 fw-bold">OUR WORK</h1>
          <div class="toggle-button-cover">
            <div id="button-3" class="button r">
              <input
                class="checkbox"
                type="checkbox"
                onClick={() => setIsVfx(!isVfx)}
              />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
        <div className="row">{renderVideos()}</div>
        {/* Modal to display the selected video */}
        <Modal
          show={showModal}
          onHide={handleClose}
          dialogClassName="modal-css modal-fullscreen-md-down"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedVideo?.alt}</Modal.Title>{" "}
            {/* Show video title */}
          </Modal.Header>
          <Modal.Body>
            {selectedVideo && selectedVideo.youtubeLink && (
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={`${selectedVideo.youtubeLink}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={selectedVideo.alt}
                ></iframe>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Work;
