import photo2 from "../Assets/photo2.png";
import photo3 from "../Assets/photo3.png";
import photo4 from "../Assets/photo4.png";
import photo5 from "../Assets/photo5.png";
import photo7 from "../Assets/photo7.png";
import photo8 from "../Assets/photo8.png";
import photo9 from "../Assets/photo9.png";
import photo10 from "../Assets/photo10.png";
import photo12 from "../Assets/photo12.png";
import photo13 from "../Assets/photo13.png";
import photo15 from "../Assets/photo15.png";
import photo16 from "../Assets/photo16.png";
import photo17 from "../Assets/photo17.png";
import photo18 from "../Assets/photo18.png";
import photo19 from "../Assets/photo19.png";
import photo20 from "../Assets/photo20.png";
import photo21 from "../Assets/photo21.png";
import photo22 from "../Assets/photo22.png";
import photo23 from "../Assets/photo23.png";
import photo24 from "../Assets/photo24.png";
import photo25 from "../Assets/photo25.png";
import photo26 from "../Assets/photo26.png";
import photo27 from "../Assets/photo27.png";
import photo28 from "../Assets/photo28.png";
import photo29 from "../Assets/photo29.png";
import photo30 from "../Assets/photo30.png";
import photo31 from "../Assets/photo31.png";
import photo32 from "../Assets/photo32.png";
import photo33 from "../Assets/photo33.png";
import photo34 from "../Assets/photo34.png";
import photo35 from "../Assets/photo35.png";

const vfx_videos = [
  {
    src: photo27,
    alt: "Lyfestyle",
    videoTitle: "Lyfestyle (Album Trailer)",
    artistName: "Yeat",
    youtubeLink: "https://www.youtube.com/embed/uf2JSk7vlto",
  },
  {
    src: photo29,
    alt: "Murdergram Deux",
    videoTitle: "Murdergram Deux",
    artistName: "LL COOL J",
    youtubeLink: "https://www.youtube.com/embed/50Tl8E0Vvms",
  },
  {
    src: photo35,
    alt: "Mafiathon",
    videoTitle: "Mafiathon 2 (Official Trailer)",
    artistName: "Kai Cenat",
    youtubeLink: "https://www.youtube.com/embed/1VTR0-8jgng",
  },

  {
    src: photo24,
    alt: "Hate Me",
    videoTitle: "Hate Me",
    artistName: "Lil Yachty & Ian",
    youtubeLink: "https://youtube.com/embed/FjFPy3N2tV4",
  },
  {
    src: photo26,
    alt: "Still In The Paint",
    videoTitle: "Still In The Paint",
    artistName: "Denzel Curry",
    youtubeLink: "https://youtube.com/embed/B0kJ5Kxqm14",
  },

  {
    src: photo23,
    alt: "Tobey",
    videoTitle: "Tobey",
    artistName: "Eminem",
    youtubeLink: "https://youtube.com/embed/CanCZktm0TQ",
  },
  {
    src: photo18,
    alt: "THE THIN GREY LINE",
    videoTitle: "THE THIN GREY LINE",
    artistName: "$UICIDEBOY$",
    youtubeLink: "https://www.youtube.com/embed/xmyZHot0Lqc",
  },
  {
    src: photo28,
    alt: "Yes",
    videoTitle: "Yes",
    artistName: "Big Sean",
    youtubeLink: "https://youtube.com/embed/YIgwVgcuwWc",
  },

  {
    src: photo22,
    alt: "Rolling Loud x Stizzy #2",
    artistName: "Rolling loud x Stiiizy",
    youtubeLink: "https://youtube.com/embed/-hAiTlEcki4",
  },
  {
    src: photo31,
    alt: "F*CK SWAG",
    videoTitle: "F*CK SWAG",
    artistName: "Nettspend",
    youtubeLink: "https://youtube.com/embed/BRfZe0mLcpw",
  },
  {
    src: photo19,
    alt: "Shibuya",
    videoTitle: "Shibuya",
    artistName: "Ski Mask the Slump God",
    youtubeLink: "https://www.youtube.com/embed/t4xfQDvrfqQ",
  },
  {
    src: photo30,
    alt: "I Rot, I Rot.",
    videoTitle: "I Rot, I Rot.",
    artistName: "che",
    youtubeLink: "https://youtube.com/embed/dm_CO-OUChs",
  },

  {
    src: photo16,
    alt: "EXCALIBUR",
    videoTitle: "EXCALIBUR",
    artistName: "DUCKBOY",
    youtubeLink: "https://www.youtube.com/embed/IP-XtEYWfg4",
  },

  {
    src: photo15,
    alt: "Quevedo",
    videoTitle: "Quevedo",
    artistName: "LA ÚLTIMA",
    youtubeLink: "https://www.youtube.com/embed/AwSFuXm5wxg",
  },
  {
    src: photo34,
    alt: "KEEP UP",
    videoTitle: "KEEP UP",
    artistName: "ODETARI",
    youtubeLink: "https://www.youtube.com/embed/sYpSpHq9ziM",
  },
];

const unusedVFX_videos = [
  {
    src: photo33,
    alt: "Sorry Not Sorry",
    videoTitle: "Sorry Not Sorry",
    artistName: "Lil Yachty & Veeze",
    youtubeLink: "https://youtube.com/embed/ny-IxcS7Yb4",
  },
  {
    src: photo32,
    alt: "Passenger Princess",
    videoTitle: "Passenger Princess",
    artistName: "Aminé & Smino",
    youtubeLink: "https://youtube.com/embed/RoeyqC1kZoc",
  },
  {
    src: photo25,
    alt: "La Cruz",
    videoTitle: "La Cruz",
    artistName: "LIA KALI",
    youtubeLink: "https://youtube.com/embed/T3lh4AaFr8s",
  },

  {
    src: photo7,
    alt: "VANISH",
    videoTitle: "VANISH",
    artistName: "Dro Kenji",
    youtubeLink: "https://www.youtube.com/embed/pHJqjE3y_W0",
  },
  {
    src: photo5,
    alt: "Let’s Lose Our Minds",
    videoTitle: "Let’s Lose Our Minds",
    artistName: "SoFaygo",
    youtubeLink: "https://www.youtube.com/embed/yG28xiNlG3c",
  },
  {
    src: photo3,
    alt: "COPE",
    videoTitle: "COPE",
    artistName: "Jamario",
    youtubeLink: "https://www.youtube.com/embed/xjuu2JgmPCY",
  },
  {
    src: photo4,
    alt: "USED2KNOW",
    videoTitle: "USED2KNOW",
    artistName: "Dro Kenji",
    youtubeLink: "https://www.youtube.com/embed/Ih7DEc82myw",
  },
  {
    src: photo13,
    alt: "Daily",
    videoTitle: "Daily",
    artistName: "Jamario",
    youtubeLink: "https://youtube.com/embed/oEKgefVwM4M?si=IhLcGuZbvQQLZ3QZ",
  },
  {
    src: photo2,
    alt: "Trippie Redd’s ALLTY5 Toy Shop Speakeasy",
    videoTitle: "Trippie Redd’s ALLTY5 Toy Shop Speakeasy",
    artistName: "Trippie Redd",
    youtubeLink: "https://www.youtube.com/embed/zUwsZW93gSY",
  },
  {
    src: photo17,
    alt: "DOOR TO DUSK",
    videoTitle: "DOOR TO DUSK",
    artistName: "Odetari",
    youtubeLink: "https://www.youtube.com/embed/LlMExSAFMxg",
  },

  {
    src: photo20,
    alt: "Headrush",
    videoTitle: "Headrush",
    artistName: "Ski Mask The Slump God",
    youtubeLink: "https://www.youtube.com/embed/h11clvpGuNI",
  },
  {
    src: photo21,
    alt: "Rolling Loud x STIIIZY #1",
    videoTitle: "Rolling Loud x STIIIZY #1",
    artistName: "The Loudest Pack in Town",
    youtubeLink: "https://www.youtube.com/embed/8Um0IcbXIhc",
  },
];

const twoD_videos = [
  {
    src: photo8,
    alt: "Music Video 8",
    videoTitle: "IDWK",
    artistName: "347aidan",
    youtubeLink: "https://www.youtube.com/embed/7-aP9PfRWa8",
  },
  {
    src: photo9,
    alt: "Music Video 9",
    videoTitle: "2055",
    artistName: "Sleepy Hallow",
    youtubeLink: "https://www.youtube.com/embed/y1xZ_kAhjMc",
  },
  {
    src: photo10,
    alt: "Music Video 10",
    videoTitle: "Drum Dummy",
    artistName: "Sheff G",
    youtubeLink: "https://www.youtube.com/embed/Fwoi4106eRU",
  },
  {
    src: photo12,
    alt: "Music Video 11",
    videoTitle: "Break From It",
    artistName: "Sheff G",
    youtubeLink: "https://www.youtube.com/embed/YqhCgEFuHXQ",
  },
];

export { vfx_videos, twoD_videos };
